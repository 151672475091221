<template>
  <div class="campus">
    <div class="title">
      <span class="s">学校列表</span>
    </div>
    <div class="search-cell">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="学校名称：">
          <el-input
            v-model="formInline.school_name"
            size="mini"
            placeholder="角色名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select size="mini" v-model="formInline.status" placeholder="全部">
            <el-option label="全部" value=""></el-option>
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            size="mini"
            type="primary"
            @click="
              page = 1;
              getSchoolList();
            "
            >查询</el-button
          >

          <el-button size="mini" plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-table
        size="small "
        v-loading="loading"
        border
        :data="tableData.list"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="id"
          label="序号"
          width="80"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_id"
          label="学校ID"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="学校名称"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="domain"
          label="学校域名"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="status"
          label="状态"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.status == 0 ? "关闭" : "启用" }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        :page-size="page_size"
        :current-page="page"
        @current-change="pageChange"
        style="margin-top: 20px"
        background
        layout="prev, pager, next"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { schoolList } from "@/api/admin.js";
export default {
  data() {
    return {
      formInline: {
        school_name: "",
        status: "",
      },
      page: 1,
      page_size: 10,
      tableData: {
        list: [],
      },
      loading: true,
    };
  },
  created() {
    this.getSchoolList();
  },
  methods: {
    getSchoolList() {
      this.loading = true;
      schoolList({
        ...this.formInline,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    // 重置
    handleReset() {
      this.formInline = {
        school_name: "",
        status: "",
      };
      this.page = 1;
      this.getSchoolList();
    },
    pageChange(index) {
      this.page = index;
      this.getSchoolList();
    },
  },
};
</script>
<style lang="less">
.campus {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      color: #ee4d2d;
      border-bottom: 2px solid #ee4d2d;
      padding: 0 10px;
      padding-bottom: 10px;
    }
  }
  .search-cell {
    padding: 10px 0;
  }
  .el-form-item__label {
    font-size: 12px;
  }
}
</style>